<template>
  <div
    class="u-display-flex u-flex-direction-column u-bg-color-white-base card u-spacing-p-l"
  >
    <section class="u-display-flex u-flex-direction-column">
      <change-bid-multiplier
        v-if="showComponent"
        :prefilled-state="displayState"
        :default-state="defaultState"
        @cancel="closePanelMethod"
        @save="apply"
      />
      <div v-else>
        <loader
          v-if="isLoading || entityInfo.status === 'loading'"
          class="fill--parent"
          :loading="isLoading || entityInfo.status === 'loading'"
          :color="'#3fadf7'"
          :size="'4rem'"
        />
        <div class="u-display-flex u-spacing-mb-l">
          <rb-button
            :click-fn="openPanel"
            :text="'Change Bid Multiplier'"
            :type="'filled'"
            class="u-spacing-mr-xl"
          />
        </div>
        <section>
          <div class="u-display-flex u-flex-direction-column">
            <div
              v-for="(key, index) in Object.keys(displayObject)"
              :key="key"
              :class="{ 'u-spacing-mt-l': index }"
            >
              <span
                class="u-font-size-5 u-font-weight-600 u-color-grey-base u-text-case-title"
              >
                {{ displayObject[key].label }}:
              </span>
              <div class="u-spacing-mt-m u-display-flex">
                <div
                  v-for="(subGroupKey, subIndex) in Object.keys(
                    displayObject[key].subGroup
                  )"
                  :key="subGroupKey"
                  class="u-display-flex u-flex-direction-column"
                  :class="{ 'u-spacing-ml-m': subIndex }"
                >
                  <span
                    class="u-font-size-6 u-font-weight-600 u-color-grey-lighter u-spacing-mb-xs"
                    >{{ displayObject[key].subGroup[subGroupKey].label }}</span
                  >
                  <div class="u-position-relative u-display-flex">
                    <rb-input
                      v-model="displayObject[key].subGroup[subGroupKey].value"
                      readonly
                      type="number"
                      class="u-width-100px height-custom-bid u-bg-color-grey-white"
                      :disabled="true"
                    />
                    <div
                      class="u-position-absolute u-font-size-6"
                      style="top: 12px; right: 4px"
                    >
                      %
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
import HttpService from '@/utils/services/http-service';
import ChangeBidMultiplier from '@/pages/entity_details/configs/walmart/components/changeBidMultiplier.vue';
import loader from '@/components/basic/loader';
import { cloneDeep, isEqual } from 'lodash';
export default {
  components: {
    ChangeBidMultiplier,
    loader
  },
  props: {
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      displayState: {},
      defaultState: {},
      typeMap: {},
      showComponent: false,
      isLoading: true,
      updateMetadata: null,
      tabConfig: null,
      getState: null
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    entityInfo() {
      return this.$store.getters[this.getState];
    },
    displayObject() {
      const context = this;
      return Object.keys(this.displayState || {})?.reduce((result, key) => {
        result[key] = { ...(result[key] || {}) };
        result[key].label = key;
        result[key].subGroup = context.displayState[key];
        return result;
      }, {});
    }
  },
  async created() {
    const tabConfig = this.entityConfig?.tabs?.[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.defaultState = cloneDeep(tabConfig?.defaultState);
    this.displayState = cloneDeep(tabConfig?.defaultState);
    this.typeMap = tabConfig?.typeMap;
    await this.tabConfig?.init?.(this);
  },
  methods: {
    fetchData(type) {
      let reqPayload;
      reqPayload = {
        retailer: this.retailer,
        campaignId: this.$route?.params?.entityId,
        advertiserId: this.$route?.query?.advertiser_id,
        adGroupId: this.$route?.params?.subEntityId,
        filterName: null,
        filterDate: null,
        type
      };

      return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', reqPayload);
    },
    openPanel() {
      this.showComponent = true;
    },
    closePanelMethod() {
      this.showComponent = false;
    },
    async apply(data) {
      await this.tabConfig.apply(this, data);
    }
  }
};
</script>
<style lang="css" scoped>
.u-min-height-100 {
  min-height: 100%;
}
</style>
