<template>
  <article class="u-display-flex u-width-100 u-flex-direction-column">
    <h1 class="u-font-size-5 u-font-weight-600 u-text-uppercase u-spacing-mb-l">
      Change Bid Multiplier
    </h1>
    <section class="u-display-flex">
      <section
        class="u-border-width-s u-border-right u-border-color-grey-xxx-light u-spacing-mr-l"
      >
        <rbRadioGroup
          :radio-options="radioOptions"
          :value="selectedRadioValue"
          :is-column="true"
          :container-class="'global-radio-wrapper u-spacing-mr-l u-spacing-mt-s'"
          @onRadioChanged="onRadioChanged"
        />
      </section>
      <section>
        <div class="u-display-flex u-flex-direction-column">
          <div
            v-for="(key, index) in Object.keys(displayObject)"
            :key="key"
            :class="{ 'u-spacing-mt-l': index }"
          >
            <span
              class="u-font-size-5 u-font-weight-600 u-color-grey-base u-text-case-title"
            >
              {{ displayObject[key].label }}:
            </span>
            <div class="u-spacing-mt-m u-display-flex">
              <div
                v-for="(subGroupKey, subIndex) in Object.keys(
                  displayObject[key].subGroup
                )"
                :key="subGroupKey"
                class="u-display-flex u-flex-direction-column"
                :class="{ 'u-spacing-ml-m': subIndex }"
              >
                <span
                  class="u-font-size-6 u-font-weight-600 u-color-grey-lighter u-spacing-mb-xs"
                  >{{ displayObject[key].subGroup[subGroupKey].label }}</span
                >
                <div class="u-position-relative u-display-flex u-width-100">
                  <rb-input
                    v-model="internalState[key][subGroupKey].value"
                    type="number"
                    class="u-width-100 height-custom-bid u-bg-color-grey-white"
                    :min="0"
                    :step="0.01"
                    @input="
                      ($event) =>
                        handleInput(internalState, key, subGroupKey, $event)
                    "
                  />
                  <div
                    class="u-position-absolute u-font-size-6"
                    style="top: 12px; right: 4px"
                  >
                    %
                  </div>
                </div>
                <div
                  v-if="(errorState || {})[subGroupKey]"
                  class="errorMessage u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center u-spacing-mt-s u-max-width-260px"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--small"
                    :icon="'error-fill'"
                  />
                  <div class="u-flex-0 u-spacing-ml-xxs u-spacing-mt-xxxs">
                    {{ errorState[subGroupKey] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <section class="u-display-flex">
      <div class="u-spacing-pt-xl">
        <footerButtons
          :button-min-width="'u-min-width-100px'"
          :disable-left-btn="disableLeftButton"
          :left-button-type="disableLeftButton ? 'filled' : 'hollow'"
          @onClickBtnRight="onCancel"
          @onClickBtnLeft="onSave"
        />
      </div>
    </section>
  </article>
</template>

<script>
import Vue from 'vue';
import rbRadioGroup from '@/components/ams/campaign_creation/steps/common/rbRadioGroupSubLabel.vue';
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ChangeBidMultiplier',
  components: {
    rbRadioGroup,
    footerButtons
  },
  props: {
    radioOptions: {
      type: Array,
      default: () => {
        return [
          {
            label: 'Set new bid multiplier',
            value: 'set_new_bid_multiplier',
            type: 'set'
          },
          {
            label: 'Add to bid multiplier',
            value: 'add_to_bid_multiplier',
            type: 'add'
          },
          {
            label: 'Subtract from bid multiplier',
            value: 'subtract_from_bid_multiplier',
            type: 'subtract'
          }
        ];
      }
    },
    defaultState: {
      type: Object,
      default: () => ({})
    },
    prefilledState: {
      type: Object,
      default: () => ({})
    },
    errorStringTemplate: {
      type: String,
      default: 'The multiplier % should be greater than min'
    },
    roundOff: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      selectedRadioValue: '',
      internalState: cloneDeep(this.prefilledState),
      errorState: {}
    };
  },
  computed: {
    displayObject() {
      const context = this;
      return Object.keys(this.defaultState || {})?.reduce((result, key) => {
        result[key] = { ...(result[key] || {}) };
        result[key].label = key;
        result[key].subGroup = context.internalState[key];
        return result;
      }, {});
    },
    fallbackState() {
      const state =
        this[
          this.selectedRadioValue === 'set' ? 'prefilledState' : 'defaultState'
        ];
      return state;
    },
    disableLeftButton() {
      let disable = !Object.keys(
        this.checkifValuesAreUpdated(this.internalState, this.fallbackState)
      ).length;
      if (!disable) {
        const errorArray = Object.values(this.errorState);
        if (errorArray.length && errorArray.some(Boolean)) {
          disable = true;
        }
      }
      return disable;
    }
  },
  created() {
    this.selectedRadioValue = this.radioOptions[0].type;
  },
  methods: {
    checkifValuesAreUpdated() {
      const type = this.selectedRadioValue;
      let result = {};
      const context = this;
      switch (type) {
        case 'set': {
          Object.keys(this.prefilledState).forEach((key) => {
            Object.keys(context.prefilledState[key]).forEach((subKey) => {
              Vue.set(context.errorState, subKey, ''); // reset errors
              if (
                context.internalState[key][subKey].value !==
                context.prefilledState[key][subKey].value
              ) {
                const updatedState = parseFloat(
                  context.internalState[key][subKey].value || 0
                );
                if (
                  this.isValid(
                    updatedState,
                    context.defaultState[key][subKey].validation?.min,
                    context.defaultState[key][subKey].validation?.max
                  )
                ) {
                  result[key] = { ...(result[key] || {}) };
                  result[key][subKey] = updatedState;
                } else {
                  const errorString = context.generateErrorString(
                    context.defaultState[key][subKey].validation?.min,
                    context.defaultState[key][subKey].validation?.max,
                    context.errorStringTemplate
                  );
                  Vue.set(context.errorState, subKey, errorString);
                }
              }
            });
          });
          break;
        }
        case 'add': {
          Object.keys(this.defaultState).forEach((key) => {
            Object.keys(context.defaultState[key]).forEach((subKey) => {
              const defaultState = parseFloat(
                context.defaultState[key][subKey].value || 0
              );
              const updatedState = parseFloat(
                context.internalState[key][subKey].value || 0
              );
              const initialState = parseFloat(
                context.prefilledState[key][subKey].value || 0
              );
              Vue.set(context.errorState, subKey, ''); // reset errors
              if (defaultState !== updatedState) {
                const finalValue = initialState + updatedState;
                if (
                  this.isValid(
                    finalValue,
                    context.defaultState[key][subKey].validation?.min,
                    context.defaultState[key][subKey].validation?.max
                  )
                ) {
                  result[key] = { ...(result[key] || {}) };
                  result[key][subKey] = finalValue;
                } else {
                  const errorString = context.generateErrorString(
                    context.defaultState[key][subKey].validation?.min,
                    context.defaultState[key][subKey].validation?.max,
                    context.errorStringTemplate
                  );
                  Vue.set(context.errorState, subKey, errorString);
                }
              }
            });
          });
          break;
        }
        case 'subtract': {
          Object.keys(this.defaultState).forEach((key) => {
            Object.keys(context.defaultState[key]).forEach((subKey) => {
              const defaultState = parseFloat(
                context.defaultState[key][subKey].value || 0
              );
              const updatedState = parseFloat(
                context.internalState[key][subKey].value || 0
              );
              const initialState = parseFloat(
                context.prefilledState[key][subKey].value || 0
              );
              Vue.set(context.errorState, subKey, ''); // reset errors
              if (defaultState !== updatedState) {
                const finalValue = initialState - updatedState;
                if (
                  this.isValid(
                    finalValue,
                    context.defaultState[key][subKey].validation?.min,
                    context.defaultState[key][subKey].validation?.max
                  )
                ) {
                  result[key] = { ...(result[key] || {}) };
                  result[key][subKey] = finalValue;
                } else {
                  const errorString = context.generateErrorString(
                    context.defaultState[key][subKey].validation?.min,
                    context.defaultState[key][subKey].validation?.max,
                    context.errorStringTemplate
                  );
                  Vue.set(context.errorState, subKey, errorString);
                }
              }
            });
          });
          break;
        }
        default: {
          break;
        }
      }
      return result;
    },
    onSave() {
      const result = this.checkifValuesAreUpdated();
      this.$emit('save', result);
    },
    onCancel() {
      this.$emit('cancel');
    },
    onRadioChanged(val) {
      this.selectedRadioValue = val;
      this.internalState = cloneDeep(this.fallbackState);
      this.errorState = {};
    },
    generateErrorString(min = {}, max = {}, errorStringTemplate = '') {
      const bothLimitsExclusiveOrInclusive =
        !this.isNullOrUndefined(min.value) && !this.isNullOrUndefined(max.value)
          ? (min.exclusive && max.exclusive && ' (both exclusive)') || // checks if both exclusive
            (!min.exclusive && !max.exclusive && ' (both inclusive)') // checks if both inclusive
          : '';
      const minVal = !this.isNullOrUndefined(min.value)
        ? `${min.value}${
            bothLimitsExclusiveOrInclusive
              ? ''
              : ` (${min.exclusive ? 'exclusive)' : 'inclusive)'}`
          }`
        : '';
      const maxVal = !this.isNullOrUndefined(max.value)
        ? `${max.value}${
            bothLimitsExclusiveOrInclusive
              ? ''
              : ` (${max.exclusive ? 'exclusive)' : 'inclusive)'}`
          }`
        : '';
      return errorStringTemplate
        .replace('min', minVal)
        .replace('max', maxVal)
        .concat(bothLimitsExclusiveOrInclusive);
    },
    isValid(value, min = {}, max = {}) {
      if (
        !this.isNullOrUndefined(min.value) &&
        !this.isNullOrUndefined(max.value)
      ) {
        // when both validations are present
        if (
          (min.exclusive ? value > min.value : value >= min.value) &&
          (max.exclusive ? value < max.value : value <= max.value)
        ) {
          return true;
        }
        return false;
      } else if (!this.isNullOrUndefined(min.value)) {
        // when only min validation is present
        if (min.exclusive ? value > min.value : value >= min.value) {
          return true;
        }
        return false;
      } else if (!this.isNullOrUndefined(max.value)) {
        // when only max validation is present
        if (max.exclusive ? value < max.value : value <= max.value) {
          return true;
        }
        return false;
      } else {
        // no validations
        return true;
      }
    },
    isNullOrUndefined(val) {
      if (val === null || val === undefined) {
        return true;
      }
      return false;
    },
    handleInput(state, key, subKey, value) {
      this.$nextTick(() => {
        state = cloneDeep(state);
        state[key][subKey].value = this.handleRoundOff(value);
        Vue.set(this.internalState, key, state[key]);
      });
    },
    handleRoundOff(valueToUpdate, roundOff = this.roundOff) {
      return valueToUpdate === ''
        ? valueToUpdate
        : (
            Math.round(parseFloat(valueToUpdate) * Math.pow(10, roundOff)) /
            Math.pow(10, roundOff)
          ).toString();
    }
  }
};
</script>
